
























































import { Component, Vue } from 'vue-property-decorator'
import { RouteNames } from '@/router'
import { BButton, BOverlay, BSpinner } from 'bootstrap-vue'

@Component({
  components: {
    BOverlay,
    BSpinner,
    BButton
  }
})
export default class Scanner extends Vue {
  readonly RouteNames = RouteNames

  back() {
    this.$router.back()
  }
}
